/* eslint-disable import/prefer-default-export */

const DEFAULT_COMPANY = {
  id: null,
  name: null,
  accountId: null,
};

const DEFAULT_USER = {
  uuid: null,
};

export { DEFAULT_COMPANY, DEFAULT_USER };
