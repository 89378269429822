<template>
  <VCard>
    <VToolbar flat>
      <VBtn
        v-if="staffPositionParams"
        icon
        @click="$router.go(-1)"
      >
        <VIcon>fal fa-chevron-left</VIcon>
      </VBtn>

      <VToolbarTitle>
        {{ company.name }}
      </VToolbarTitle>

      <VSpacer />
    </VToolbar>

    <VDivider />

    <VCardText>
      <VExpansionPanels
        accordion
        flat
      >
        <VExpansionPanel>
          <VExpansionPanelHeader>JSON</VExpansionPanelHeader>
          <VExpansionPanelContent>
            <code class="elevation-0 d-block">{{ company }}</code>
          </VExpansionPanelContent>
        </VExpansionPanel>
      </VExpansionPanels>
    </VCardText>
  </VCard>
</template>

<script>
import { DEFAULT_COMPANY } from './common';

export default {
  name: 'CompanyCard',

  props: {
    company: {
      type: Object,
      default: () => ({ ...DEFAULT_COMPANY }),
    },
  },

  computed: {
    staffPositionParams() {
      return this.$route.params.staffPosition;
    },
  },
};
</script>
